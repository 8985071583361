<template src="./ForgetPassword.html">
</template>

<script>
    import CustomSnackbar from "../../components/CustomSnackbar.vue";
    import CustomLoading from "../../components/CustomLoading.vue";
    export default {
        name: "ForgetPassword",

        components: {
            CustomSnackbar,
            CustomLoading,
        },

        created() {
            this.getLookups();

            this.forgetPassword.nationalityId = this.$types.libyanNationality;
        },

        computed: {

            nationalities() {
                return this.$store.state.lookup.nationalities;
            },

            //========= Rules ===========//
            checkValueRules() { return this.$validation.checkValueRules; },
            nationalNoRules() { return this.$validation.nationalNoRules; },
            checkPhoneNo() { return this.$validation.checkPhoneNo; },

        },

        data: () => ({

            valid: true,
            loading: false,

            forgetPassword: {
                nationalityId: null,
                nationalNo: null,
                passportNo: null,
                phoneNo: null,
                phoneOperator: 21891,
                recaptchaToken: null
            },
            phoneOperators: [
                { text: '21891', value: 21891 },
                { text: '21892', value: 21892 },
                { text: '21893', value: 21893 },
                { text: '21894', value: 21894 },
            ],
            showPass: false,
            verify: false,
            personId: null,
            password: null,
            passwordRecaptchaToken: null,
            resendPasswordRecaptchaToken: null,
            timerCount: 120
        }),

        watch: {

            timerCount: {
                handler(value) {
                    if (value > 0) {
                        setTimeout(() => {
                            this.timerCount--;
                        }, 1000);
                    }
                },
                immediate: true
            }
        },
        methods: {

            async recaptcha() {
                await this.$recaptchaLoaded()
                this.forgetPassword.recaptchaToken = await this.$recaptcha('forgetPass')
                this.forgetPass()
            },
            forgetPass() {
                if (this.$refs.forgetPassword.validate()) {

                    let personInfo = {
                        nationalityId: this.forgetPassword.nationalityId,
                        nationalNo: this.forgetPassword.nationalNo,
                        passportNo: this.forgetPassword.passportNo,
                        phoneNo: `${this.forgetPassword.phoneOperator}${this.forgetPassword.phoneNo}`,
                        recaptchaToken: this.forgetPassword.recaptchaToken
                    }
                    this.$http.forgetPassword(personInfo)
                        .then((res) => {
                            this.verify = true;
                            this.timerCount = 120;
                            this.personId = res.data.personId;
                        })
                        .catch(err => {
                            this.$store.commit("snackbar/show",
                                { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                            );
                        })
                }
                else {
                    this.$store.commit("snackbar/show", { message: "الرجاء التأكد من صحة البيانات", type: "error" })
                }
                return;
            },


            async recaptchaResendPassword() {
                await this.$recaptchaLoaded()
                this.resendPasswordRecaptchaToken = await this.$recaptcha(`resend${this.personId}password`)
                this.resendPassword()
            },
            resendPassword() {
                let passowrdInfo = {
                    personId: this.personId,
                    recaptchaToken: this.resendPasswordRecaptchaToken,
                };

                this.$http.resendPassword(passowrdInfo)
                    .then((res) => {
                        this.$store.commit("snackbar/show", { message: res.data.msg, icon: "mdi-check-all", type: "success" });
                        this.timerCount = 120;
                    })
                    .catch(err => {
                        this.$store.commit("snackbar/show",
                            { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                        );
                    })
            },

            async recaptchaVerifyPassowrd() {
                await this.$recaptchaLoaded()
                this.passwordRecaptchaToken = await this.$recaptcha('verifyPassowrd')
                this.verifyPassowrd()
            },
            verifyPassowrd() {

                let passowrdInfo = {
                    personId: this.personId,
                    password: this.password,
                    recaptchaToken: this.passwordRecaptchaToken,
                };

                this.$http.verifyPassowrd(passowrdInfo)
                    .then((res) => {
                        this.$store.commit("snackbar/show", { message: "تسجيل دخول ناجح", icon: "mdi-check-all", type: "success" });
                        this.$store.dispatch("auth/autoLogin", res.data.loginResult)
                            .then(() => {
                                if (this.$store.state.auth.lastRoute) {
                                    this.$router.push({ name: this.$store.state.auth.lastRoute });
                                }
                                else
                                    this.$router.push("/")
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    })
                    .catch(err => {
                        this.$store.commit("snackbar/show",
                            { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                        );
                    })
            },

            back() {
                this.$router.push("/sign-form")
            },
            //============= lookups ================//
            getLookups() {
                this.$store.dispatch("lookup/getNationalities");
            },
        }
    };
</script>
<style lang="scss">
    .forget-pass-page {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .forget-pass-container {
            padding: 30px;

            form {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: stretch;
                min-height: 320px;
            }

            .v-btn:not(.v-btn--round).v-size--default {
                height: 50px;
                min-width: 100%;
                border-radius: 1.25rem;
            }
        }
    }

    .phone-no {
        display: flex;
        align-items: center;

        .operator {
            margin-top: 12px !important;
            width: 20%
        }
    }
</style>
